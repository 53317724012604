<template>
    <div
        class="section section-shaped section-zero min-vh-100 d-flex justify-content-center align-items-center"
        :class="
            type_active === 'remember' || type_active === 'login-token' || type_active === 'login-ba'
                ? 'large-one'
                : 'large-two'
        "
    >
        <div class="shape shape-style-1 bg-gradient-lighter"></div>
        <div class="row justify-content-center container">
            <div class="col-12 col-lg-9 container-main shadow" v-if="type_active !== 'not-have-permission'">
                <div
                    class="content-box content-login"
                    :class="{ 'border-0': config.hide_register_btn }"
                    v-if="type_active === 'login' || type_active === 'register'"
                >
                    <span class="title">{{ $t('ct-login') }}</span>
                    <login @change="changeType"></login>

                    <div>
                        <a href="#" class="text-forgot-password" @click.prevent="changeType('remember')">{{
                                $t('ct-forgot-password')
                            }}</a>
                    </div>
                </div>

                <template v-if="!config.hide_register_btn">
                    <div class="content-box content-login" v-if="type_active === 'login' || type_active === 'register'">
                        <span class="title">{{ $t('ct-register') }}</span>
                        <register :areas_register="areas_register" @change="changeType"></register>
                    </div>
                </template>

                <div class="content-box content-forgot forgot" v-if="type_active === 'remember'">
                    <span class="title">{{ $t('ct-forgot-password') }}</span>
                    <remember-password @change="changeType"></remember-password>

                    <div class="mx-5 mt-2 d-flex">
                        <div class="text-center flex-grow-1">
                            <template v-if="!config.hide_register_btn">
                                <a href="#" class="helper-forgot-password" @click.prevent="changeType('register')">{{
                                        $t('ct-register')
                                    }}</a>
                                <span class="mx-1 helper-forgot-password">|</span>
                            </template>
                            <a href="#" class="helper-forgot-password" @click.prevent="changeType('login')">{{
                                    $t('ct-sign-in')
                                }}</a>
                        </div>
                    </div>
                </div>

                <div class="content-box content-forgot forgot" v-if="type_active === 'login-token'">
                    <span class="title">{{ $t('ct-access-with-token') }}</span>
                    <login-token @change="changeType"></login-token>
                </div>

                <div class="content-box content-forgot forgot" v-if="type_active === 'login-ba'">
                    <span class="title">{{ $t('ct-access-ba-credentials') }}</span>
                    <login-builderall @change="changeType"></login-builderall>
                </div>
            </div>

            <div class="col-12 col-lg-6" v-else-if="type_active === 'not-have-permission'">
                <not-have-permission></not-have-permission>
            </div>
        </div>
    </div>
</template>

<script>
import {mixinRestrictAreaConfig, mixinRestrictAreaForm} from '@/js/mixins/restrictAreaForm';

export default {
    name: 'TwoColumnsPage',
    mixins: [mixinRestrictAreaConfig, mixinRestrictAreaForm],
};
</script>

<style lang="scss" scoped>
.title {
    display: block;
    text-align: left;
    font-size: 18px;
    margin-bottom: 15px;
}
</style>
